import api from "../_app/api";
import { CommunicationDetail } from "./types";
import { SORT_REQUEST_PARAMETER_NAME, SortRequestParam } from "../_app/components/Table/helpers";
import { convertResponseToPage, isOldResponseFormat } from "../_app/utils/page";

export const getCommunications = async (page: number, limit: number, sort?: SortRequestParam) => {
  const params = new URLSearchParams();

  if (sort) {
    params.append(SORT_REQUEST_PARAMETER_NAME, `${sort.column},${sort.direction}`);
  }
  params.append("page", page.toString());
  params.append("size", limit.toString());

  const { data }: { data: any } = await api({
    method: "GET",
    url: "/communications",
    params,
  });

  if (isOldResponseFormat<CommunicationDetail>(data)) {
    return convertResponseToPage<CommunicationDetail>(data, sort !== null);
  }

  return data;
};

export const getDocumentPresignedUrl = async (communicationId: string) => {
  const { data }: { data: string } = await api({
    method: "GET",
    url: `/communications/${communicationId}/file`,
  });
  return data;
};

export const getExportCsv = async () => {
  return api({
    method: "GET",
    url: "/communications/export",
    responseType: "arraybuffer",
  });
};
