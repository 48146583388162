import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Main from "../../_app/layouts/Main";
import { Divider, Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { featureFlagsMap } from "../../feature/utils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SelectAssets } from "../components/UsageAlerts/SelectAssets";
import { SelectCriteria } from "../components/UsageAlerts/SelectCriteria";
import { SelectAction } from "../components/UsageAlerts/SelectAction";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SelectedAssets } from "../components/UsageAlerts/AlertAssetSelection";
import { permissionCodes } from "../../permission/hooks";
import { ArrowBackIos } from "@mui/icons-material";
import { useCreateNewAlert, useSingleUsageAlert, useUpdateUsageAlert } from "../hooks";
import { useSubUserLevel } from "../../user-level/hooks";
import { scrollPage } from "../../_app/utils";
import { AlertCriteria, UsageAlert } from "../types";
import { getCachedContextHierarchy } from "../../context/utils";
import { useUserData } from "../../user/hooks";

export const EditUsageAlert = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ prevPath?: string }>();
  const { id } = useParams<{ id: string }>();
  const isEdit = !!id;
  const { userLevel } = useUserData();
  const subAccountUserLevel = useSubUserLevel()?.id || 0;
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({} as UsageAlert);
  const [isAssetSelected, setAssetSelected] = useState(isEdit);
  const selectedAssetsRef = useRef<SelectedAssets | null>(null);

  const STEP_ASSETS = "Select Assets";
  const STEP_CRITERIA = "Criteria";
  const STEP_ACTION = "Action";

  const STEPS = useMemo(() => [...(!isEdit ? [STEP_ASSETS] : []), ...[STEP_CRITERIA, STEP_ACTION]], [isEdit]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    scrollPage();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollPage();
  };

  const handleReset = () => {
    setActiveStep(0);
    if (location.state.prevPath) {
      history.push(location.state.prevPath, { selectedTab: "usage-alerts" });
    } else {
      history.push(`/usage-alerts`);
    }
  };

  const { mutate: executeAddAlert } = useCreateNewAlert({
    onSuccess: () => {
      handleNext();
    },
  });

  const { mutate: executeUpdateAlert } = useUpdateUsageAlert(id, {
    onSuccess: () => {
      handleNext();
    },
  });

  const { data: alert, isSuccess: alertSuccess } = useSingleUsageAlert(id, {
    enabled: isEdit,
  });

  useEffect(() => {
    if (alertSuccess && alert) {
      setData(alert);
    }
  }, [alertSuccess, alert]);

  useEffect(() => {
    if (!isEdit && userLevel > subAccountUserLevel) {
      setData((data) => ({ ...data, level: "CLI" }));
    }
  }, [subAccountUserLevel, userLevel, isEdit]);

  const mapCriteria = (criteria: AlertCriteria[]) => {
    return criteria.map((c) => ({
      measure: c.measure?.name,
      threshold: c.threshold,
    }));
  };

  const handleSubmit = () => {
    const contextId = getCachedContextHierarchy()?.id;
    const clis = data.level === "CLI" ? selectedAssetsRef.current?.assets?.map((a) => a.cli) || [""] : [""];
    const filters = selectedAssetsRef.current?.filters;
    if (isEdit) {
      executeUpdateAlert({
        alertId: data.id,
        alertDetailUpdateConfig: {
          name: data.name,
          alertAction: {
            bar: data.alertAction?.bar?.name,
            contacts: data.alertAction.contacts,
            notifyAssetsBySMS: data.alertAction.notifyAssetsBySMS,
          },
          criteria: mapCriteria(data.criteria),
        },
      });
    } else {
      executeAddAlert({
        ...data,
        alertAction: {
          bar: data.alertAction?.bar?.name,
          contacts: data.alertAction.contacts,
          notifyAssetsBySMS: data.alertAction.notifyAssetsBySMS,
        },
        criteria: mapCriteria(data.criteria),
        accountId: contextId,
        cli: clis,
        name: data.name,
        filters,
      });
    }
  };

  const getStepContent = useCallback(() => {
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      const key = e.target.name;
      const val = e.target.value;
      if (key === "level" && val === "ACCOUNT" && data.alertAction?.bar) {
        delete data.alertAction.bar;
      }
      setData({ ...data, [key]: val });
    };

    const isStepActive = (name: string) => {
      return STEPS[activeStep] === name;
    };

    return (
      <>
        {!isEdit && isStepActive(STEP_ASSETS) && (
          <div>
            <SelectAssets
              usageAlert={data}
              onInputChange={onInputChange}
              defaultSelected={undefined}
              setSelectedAssets={(selected: SelectedAssets) => {
                setAssetSelected(!!selected.assets?.length || !!selected.filters?.length);
                selectedAssetsRef.current = selected;
              }}
              hasLevelOptions={userLevel <= subAccountUserLevel}
              hasProductOptions
            />
          </div>
        )}
        {isStepActive(STEP_CRITERIA) && (
          <div>
            <SelectCriteria usageAlert={data} setAlert={setData} isEdit={isEdit} />
          </div>
        )}
        {isStepActive(STEP_ACTION) && (
          <div>
            <SelectAction usageAlert={data} setAlert={setData} />
          </div>
        )}
      </>
    );
  }, [STEPS, activeStep, data, isEdit, subAccountUserLevel, userLevel]);

  const hasNotify =
    (data?.alertAction?.notifyAssetsBySMS && data?.alertAction?.contacts?.length === 0) ||
    (data?.alertAction?.contacts?.length &&
      !data?.alertAction?.contacts.filter((c) => !c.emailNotification && !c.smsNotification).length);
  const hasCliBar = data.product === "MOBILE" && data.level === "CLI" ? Boolean(data?.alertAction?.bar) : true;

  const isSubmitStep = activeStep === STEPS.length - 1;
  const canSubmit = hasNotify && hasCliBar;

  return (
    <Main
      data-cy="add-edit-usage-alert-page"
      featureFlag={featureFlagsMap.USAGE_ALERTS}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      needSelectedAccount={true}
    >
      <Grid className={classes.stepper}>
        <Stepper activeStep={activeStep}>
          {STEPS.map((label) => (
            <Step key={label}>
              <StepLabel>
                <Typography className={classes.bold} data-cy="step">
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container direction="column" justifyContent="center" className={classes.wrap}>
        {activeStep === STEPS.length ? (
          <Grid
            xs={12}
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "70vh" }}
          >
            <Typography variant="h3" className={classes.instructions}>
              All changes submitted
            </Typography>
            <Button variant="contained" color="primary" onClick={handleReset}>
              Back
            </Button>
          </Grid>
        ) : (
          <>
            <Divider variant="fullWidth" />
            <div className={classes.contentCtr}>{getStepContent()}</div>
            <div className={classes.stepperActions}>
              {!activeStep && (
                <Button variant="text" color="primary" onClick={handleReset} data-cy="back-initial">
                  <ArrowBackIos fontSize="small" /> Back
                </Button>
              )}
              <Button
                disabled={activeStep === 0}
                style={{ opacity: Math.min(activeStep, 1) }}
                onClick={handleBack}
                startIcon={<ArrowBackIcon />}
                className={classes.backButton}
                data-cy="back"
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={isSubmitStep ? handleSubmit : handleNext}
                color="primary"
                endIcon={<ArrowForwardIcon />}
                data-cy="next"
                disabled={
                  (STEPS[activeStep] === STEP_ASSETS &&
                    (!data?.level || !data?.product || !data?.name || (data?.level === "CLI" && !isAssetSelected))) ||
                  (STEPS[activeStep] === STEP_CRITERIA &&
                    (!data?.criteria?.[0].measure?.name || !data?.name || !data?.criteria?.[0].threshold)) ||
                  (isSubmitStep && !canSubmit)
                }
              >
                {isSubmitStep ? "Submit" : "Continue"}
              </Button>
            </div>
          </>
        )}
      </Grid>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      width: "100%",
    },
    stepper: {
      padding: "0px 0px 10px",
      width: "100%",
      "& > div": {
        padding: 0,
      },
    },
    contentCtr: {
      width: "100%",
      paddingTop: 18,
      marginBottom: 10,
    },
    stepperActions: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    backButton: {
      marginRight: theme.spacing(2),
    },
    instructions: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    bold: {
      fontWeight: 600,
    },
  }),
);

export default EditUsageAlert;
