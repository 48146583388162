import React, { useCallback, useMemo, useState } from "react";
import Main from "../../_app/layouts/Main";
import { permissionCodes } from "../../permission/hooks";
import { featureFlagsMap } from "../../feature/utils";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import { CommunicationDetail } from "../types";
import { useCommunicationFilters, useCommunications, useExportAllCommunications } from "../hooks";
import UILoader from "../../_app/components/UILoader";
import MUIDataTable, {
  CustomHeadLabelRenderOptions,
  MUIDataTableColumnDef,
  MUIDataTableColumnOptions,
  MUIDataTableState,
} from "mui-datatables";
import { rowParser, SortRequestParam } from "../../_app/components/Table/helpers";
import DownloadButton from "../components/DownloadButton";
import { capitalize } from "../../_app/utils/format";
import SortableHeaderLabel from "../../_app/components/Table/SortableHeaderLabel";
import { UIAlert, UIGrid } from "../../_app/components";
import FiltersCard from "../../filter/components/FiltersCard";
import { downloadFile, extractFilenameFromHeaders } from "../../_app/utils";
import { AxiosResponse } from "axios";
import { Button, Stack } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const rowCountOptions = [10, 25, 50];
const COMMUNICATIONS_FILTERS: string = "COMMUNICATIONS";

export const Communications = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<SortRequestParam>({ column: "creationDate", direction: "desc" });
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const { data, isFetching } = useCommunications(page, rowCount, sort);
  const { isFetching: isFileFetching, refetch } = useExportAllCommunications({
    onSuccess: (response: AxiosResponse) => {
      downloadFile(response?.data, extractFilenameFromHeaders(response) ?? `communication-export.csv`);
    },
  });

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  const handleSortChange = (column: string, direction: "desc" | "asc") => {
    setSort({ column: column, direction: direction });
  };

  const customHeader = useCallback(
    (options: CustomHeadLabelRenderOptions) => (
      <SortableHeaderLabel label={options.label ?? options.name} name={options.name} sort={sort} />
    ),
    [sort],
  );

  const sortOptions: MUIDataTableColumnOptions = {
    sortDescFirst: false,
    customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => customHeader(options),
  };

  const tableColumns: MUIDataTableColumnDef[] = [
    { name: "creationDate", label: "Date", options: sortOptions },
    { name: "type", label: "Type", options: sortOptions },
    { name: "subject", label: "Subject", options: sortOptions },
    { name: "recipient", label: "Recipient", options: sortOptions },
    { name: "associatedProduct", label: "Associated Product", options: sortOptions },
    { name: "" },
  ];

  const parsed = useMemo(
    () =>
      data?.content.map((item: CommunicationDetail) => {
        return rowParser([
          item?.date,
          capitalize(item?.type),
          item?.subject,
          item?.recipient,
          item?.associatedProduct,
          <DownloadButton key={item?.communicationId} communicationId={item?.communicationId} filename={item?.filename} />,
        ]);
      }) || [],
    [data],
  );

  return (
    <Main
      title={
        <>
          Communications
          <Stack direction={"row"} spacing={1}>
            <Button
              variant="text"
              color="primary"
              data-cy="export-communications-button"
              onClick={() => refetch()}
              startIcon={isFileFetching ? <UILoader size={20} /> : <DownloadIcon />}
              disabled={isFileFetching}
            >
              Export all to csv
            </Button>
          </Stack>
        </>
      }
      data-cy="communications-page"
      accessPermission={[permissionCodes.COMMUNICATIONS]}
      featureFlag={featureFlagsMap.COMMUNICATIONS_CENTRE}
      needSelectedAccount={false}
      isLoading={false}
    >
      <UIGrid item xs={12} md={12}>
        <UIAlert severity="info" className={classes.info}>
          Find relevant communications we've sent to you here. Easily access and review important information anytime, ensuring
          you stay organised and never miss an update.
        </UIAlert>
        <UIGrid item>
          <FiltersCard fetchHook={useCommunicationFilters} hasReset={false} usage={COMMUNICATIONS_FILTERS} />
        </UIGrid>
        {isFetching ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <div className={classes.ctr} data-cy="communications-table">
            <MUIDataTable
              title=""
              data={parsed}
              columns={tableColumns}
              options={{
                onTableChange: handleTableChange,
                onColumnSortChange: handleSortChange,
                pagination: true,
                page: page,
                rowsPerPage: rowCount,
                rowsPerPageOptions: rowCountOptions,
                count: data?.totalElements,
                download: false,
                elevation: 1,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                serverSide: true,
                viewColumns: false,
                sort: true,
                sortOrder: { name: sort.column, direction: sort.direction },
                search: false,
                selectableRows: "none",
                rowHover: true,
                setRowProps: (row) => ({
                  "data-cy": `row-id-${row?.[0]}`,
                }),
                setTableProps: () => ({ size: "small" }),
              }}
            />
          </div>
        )}
      </UIGrid>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      width: "100%",
      padding: "0",
      marginTop: theme.spacing(1.5),
      "& .Mui-active .MuiTableSortLabel-icon": {
        color: theme.palette.primary.main,
      },
    },
    info: {
      width: "fit-content",
      marginBottom: theme.spacing(3),
      alignItems: "center",
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flex: 1,
    },
  }),
);

export default Communications;
