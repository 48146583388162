import DetailItem from "./DetailItem";
import { createStyles, makeStyles, UIButton } from "../../../_app/components";
import DetailsCard from "./DetailsCard";
import React from "react";
import { Asset } from "../../types";
import WorldwideDataCapDetail from "./data-roaming/WorldwideDataCapDetail";

interface Props {
  showBars: () => void;
  hasBars: boolean;
  asset: Asset;
}

export const MobileDetails = ({ showBars, hasBars, asset }: Props) => {
  const classes = useStyles();

  return (
    <DetailsCard title="Mobile Details">
      <>
        <DetailItem title="Provider" value={asset.network} />
        <DetailItem title="SIM Number" value={asset.simNumber} />
        {hasBars && (
          <DetailItem
            title="Bars"
            value=""
            action={
              <UIButton
                aria-label="manage-btn"
                variant="text"
                onClick={showBars}
                className={classes.button}
                data-cy="manage-btn"
                id="manage-btn"
              >
                Manage
              </UIButton>
            }
          />
        )}
        <WorldwideDataCapDetail assetId={asset.id} />
      </>
    </DetailsCard>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      justifyContent: "flex-end",
    },
  }),
);

export default MobileDetails;
