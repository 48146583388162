import { User } from "oidc-client-ts";

export type IdentityUser = User;

export interface DecodedJWT {
  sub: string;
  client_id: string;
  tenant_ref: string;
  given_name?: string;
  family_name?: string;
  auth_time?: number;
  exp?: number;
  iss?: string;
  brand_ref?: string;
  scope?: string[];
}

export interface AuthConfig {
  tokenIssuerUrl: string;
  hostedLoginPageDomain: string;
  customerPortalClientId: string;
  partnerPortalClientId: string;
}

export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export type VerifyTotpResponse = typeof SUCCESS | typeof ERROR;
