import React, { useCallback, useEffect, useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { InfiniteData } from "react-query/types/core/types";
import { IdValueQuery } from "../../../../_app/api";
import { AlertContact, GetUsageAlertsResponse, UsageAlert } from "../../../types";
import SelectAllHeader from "./UsageAlertTableHeader";
import UILoader from "../../../../_app/components/UILoader";
import { useDeleteAlerts } from "../../../hooks";
import { useHistory, useLocation } from "react-router-dom";
import { rowParser } from "../../../../_app/components/Table/helpers";

import { RequestStatusLabel } from "../../../utils";
import { humanize } from "../../../../_app/utils/format";
import { LoadMoreFooter } from "../../../../_app/components/Table/LoadMoreFooter";

interface UsageAlertTableProps {
  result: InfiniteData<GetUsageAlertsResponse> | undefined;
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  queries: IdValueQuery[];
  rowCount: number;
  onRowCountChange: (rowCount: number) => void;
}

const tableColumns: MUIDataTableColumnDef[] = [
  {
    name: "ID",
    options: {
      display: "false",
    },
  },
  "Identifier",
  "Alert Type",
  "Product",
  "Description",
  "Action",
  "Recipients",
  "Status",
  {
    name: "",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Button
            key={value.id}
            variant="text"
            color="primary"
            data-cy="edit"
            disabled={value.disabled}
            onClick={value.onEditClick}
          >
            Edit
          </Button>
        );
      },
    },
  },
];

const UsageAlertTable: React.FC<UsageAlertTableProps> = ({
  result,
  isFetching,
  fetchNextPage,
  hasNextPage,
  queries,
  rowCount,
  onRowCountChange,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);

  const { mutate: deleteAlerts } = useDeleteAlerts();
  const parsed = useMemo(() => {
    const pages: any = result?.pages?.map((page) => {
      return page?.list?.map((item: UsageAlert) =>
        rowParser([
          item.id,
          item.cli || "All numbers on " + item.accountCode,
          humanize(item.type),
          humanize(item.product),
          item.description,
          item.alertAction?.bar?.value,
          item.alertAction?.contacts
            ?.map((contact: AlertContact) => {
              return `${contact.forename} ${contact.surname}`;
            })
            .join(", "),
          RequestStatusLabel[item.requestStatus],
          {
            id: item.id,
            disabled: item?.requestStatus?.includes("PENDING") ?? true,
            onEditClick: () => history.push(`/usage-alerts/${item.id}`, { prevPath: location.pathname }),
          },
        ]),
      );
    });
    return [].concat(...(pages ?? []));
  }, [result]);

  const handleDeleteAction = useCallback(() => {
    const selectedAlerts = selectedRows.reduce((acc: string[], r) => {
      if (parsed?.[r]) acc.push(parsed?.[r]?.[0] as string);
      return acc;
    }, []);
    deleteAlerts(selectedAlerts);
  }, [selectedRows, deleteAlerts, selectAll, queries, parsed]);

  const handleTableChange = useCallback(
    (action: string, state: any) => {
      switch (action) {
        case "changePage":
          setPage(state.page);
          break;
        case "changeRowsPerPage":
          onRowCountChange(state.rowsPerPage);
          break;
        case "rowSelectionChange":
          setSelectAll(false);
          setTimeout(() => {
            setSelectedRows(state.selectedRows.data?.map((r: any) => r?.index));
          });
          break;
      }
    },
    [onRowCountChange],
  );

  useEffect(() => {
    if (selectAll) {
      setSelectedRows(parsed.filter((item) => !(item?.[7] as string)?.includes?.("Pending")).map((_, i) => i));
    } else {
      setSelectedRows([]);
    }
  }, [selectAll, parsed]);

  const tableOptions: MUIDataTableOptions = {
    page,
    rowsPerPage: rowCount,
    rowsPerPageOptions: [25, 50, 100],
    onTableChange: handleTableChange,
    rowsSelected: selectedRows,
    pagination: false,
    customFooter: () => <LoadMoreFooter disabled={isFetching} hasMore={Boolean(hasNextPage)} onChangePage={fetchNextPage} />,
    serverSide: true,
    download: false,
    elevation: 1,
    print: false,
    responsive: "standard",
    selectToolbarPlacement: "none",
    filter: false,
    viewColumns: false,
    sort: false,
    search: false,
    rowHover: true,
    selectableRowsHeader: false,
    isRowSelectable: (dataIndex: number) => !(parsed[dataIndex]?.[7] as string)?.includes?.("Pending"),
    setTableProps: () => ({ size: "small" }),
    setRowProps: (row) => ({
      "data-cy": `usage-alert-id-${row?.[0]}`,
    }),
  };

  return (
    <>
      <SelectAllHeader
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        selectedRows={selectedRows}
        isFetching={isFetching}
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        handleDeleteAction={handleDeleteAction}
      />
      {isFetching ? (
        <div className={classes.loader}>
          <UILoader />
        </div>
      ) : (
        <div data-cy="usage-alerts-table" className={classes.tableCtr}>
          <MUIDataTable title="" data={parsed} columns={tableColumns} options={tableOptions} />
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    tableCtr: {
      marginTop: 0.5,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      "& div:first-child": {
        marginTop: 0,
      },
    },
  }),
);

export default UsageAlertTable;
